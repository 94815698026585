// //*for live api use with code uncomment============>>>>/*
export const environment = {
  production: false,
  apiUrl:'https://partnerapi.fareboutique.com/v1/',
  url:'https://partnerapi.fareboutique.com/',
  imageurl:'https://api.fareboutique.com/uploads/airlines/'
};


// //*for Demo api use uncomment============>>>>*/
// export const environment = {
//   production: false,
//   apiUrl:'https://demopartnerapi.fareboutique.com/v1/',
//   url:'https://demopartnerapi.fareboutique.com/',
//   imageurl:'https://demoapi.fareboutique.com/uploads/airlines/'
// };  

// //*for devapi use uncomment============>>>>*/
// export const environment = {
//   production: false,
//   apiUrl:'https://devpatnerapi.fareboutique.com/v1/',
//   url:'https://devpatnerapi.fareboutique.com/',
//   imageurl:'https://devapi.fareboutique.com/uploads/airlines/'
// };

